import Mainheader from './components/navbar';
import BannerOne from './components/bannerone';
import BannerTwo from './components/bannertwo';
import BannerThree from './components/bannerthree';
import Footer from './components/footer';
import BannerFour from './components/bannerfour';
import BannerFive from './components/bannerfive';
import './App.css';

function App() {
  return (
    <div>
    <Mainheader />
    <BannerOne />
    <BannerTwo />
    <BannerThree />
    <BannerFour />
    <BannerFive />
    <Footer />
    </div>
  );
}

export default App;
