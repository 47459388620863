import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Images } from '../images/index';

class Mainheader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container">
                <div className='row'>
                    <nav className={`navbar navbar-expand-lg navbar-light user-header`} id="header">
                        <img className="footer-logo" src={Images.savinwealth} alt="logo" />
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                            <ul className="nav navbar-nav">
                                {/* <li type='button' className="nav-item-home"><a>Organize</a></li> */}
                                {/* <li type='button' className="nav-item-home"><a>Market</a></li> */}
                                {/* <li type='button' className="nav-item-home"><a>Deliver</a></li> */}
                                <li className="nav-item-home">
                                <button type='button' className='btn btn-login'>Login</button></li>
                                <li className="nav-item-home">
                                <button type='button' className='btn btn-signup-nav'>Sign Up</button></li>

                            </ul>
                        </div>
                    </nav>
                </div>

            </div>

        )
    }
}

export default Mainheader;