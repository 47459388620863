import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

class BannerFive extends React.Component {
    constructor(props) {
        super(props);
    }
    createIcons(number){
        var elements = [];
        for(var i =0; i < number; i++){
            elements.push(<div className="list-item">{i} - Some text or elemet</div>);
        }
        return elements;
    }
    render() {
        return (
            <div className='banner5-background'>
                <div className="container">
                    <div className="row">
                        <div className="col-6 banner5-heading">
                            <div className="banner5-head">
                                <h3>Providing a  powerful online<br></br>
                                presence</h3>
                            </div>
                        </div>
                        <div className="col-6 banner-five">
                            <button type="button" className="btn btn-create"> Create your account</button>
                            <div className='icons-angels'>
                            

                                <FontAwesomeIcon className='icon-angle3' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle3' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle3' icon={faAngleRight} /> 
                            </div>
                            <div className='icons-angels1'>
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle1' icon={faAngleRight} />
                            </div>
                            <div className='icons-angles2'>
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                                <FontAwesomeIcon className='icon-angle2' icon={faAngleRight} />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default BannerFive;