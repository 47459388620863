import React from 'react';

class BannerTwo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="banner2-background">
                <div className="container banner-two">
                    <h3 className="banner2-head">Financial experiences built for tomorrow, not yesterday</h3>
                    <p className="banner2-para">Mutual funds are of many categories,types,plan and options.Therein lies the
                    confusion for many investor.finstop allows to simplify your search with best filters and select the appropriate schemes as
                    per your investment goals.
                    </p>
                    <button type="button" className="btn btn-primary btn-search">Search for Mutual Funds</button>
                </div>
            </div>

        )
    }
}

export default BannerTwo;