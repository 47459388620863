import React from 'react';
import { Images } from '../images/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const startYear = 2022
        const currentYear = new Date().getFullYear()
        return (
            <div className="footer-background">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className='footer-padding'>
                                <img className="footer-logo" src={Images.savinwealth} alt="logo" />
                            </div>
                            <ul className="list-unstyled list-text">
                                <li className="footer-item"> Market</li>
                                <li className="footer-item"> Deliver</li>
                                <li className="footer-item"> Pricing</li>
                                <li className="footer-item"> Privacy policy</li>
                                <li className="footer-item"> Contact us</li>
                            </ul>
                            <div class="footer-copy">
                                Copyright <strong><span>Sowise {startYear !== currentYear ?  startYear + "-" + currentYear : startYear} 
                               </span></strong>. All Rights Reserved
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 footer-right-side">
                            <div className='footer-right'>
                                <h5 className='footer-address'>Address</h5>
                                <p>No 145, Sri lakshmi nagar,IT field</p>
                                <p>Akshya nagar 1st block 1st cross, Rammurthy nagar,</p>
                                <p>Bangalore-560016.</p>
                            </div>
                            <FontAwesomeIcon className='icon-whatsapp' icon={faWhatsapp} />
                            <p className='icon-text'>+91-987654321 </p>

                            <h5 className='footer-address'>Connect with us </h5>
                            <div className='icons'>
                                <FontAwesomeIcon className='facebook-icon' icon={faFacebookF} />
                                <FontAwesomeIcon className='twitter-icon' icon={faTwitter} />
                                 <FontAwesomeIcon className='linkedin-icon' icon={faLinkedinIn} /> 

                                 {/* <img className='twitter-icon' src={Images.Twitter} alt="twitter" />  */}
                                 {/* <img className='indeed-icon' src={Images.Linkedin} alt="indeed" />  */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Footer;