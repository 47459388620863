import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';


class BannerFour extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='banner4-background'>
                <div className="container">
                    <h3 className='company-head'>Company</h3>
                    <h6 className='company-subhead'>Built to grow, scale, and adapt with your Future needs</h6>
                    <div className="row">

                        <div className="col-6 banner-team">
                            <div className="about-us">
                                <h3 className="banner-head">About us</h3>
                                <p>Highnote makes the hardest parts of fintech simple.We cover the basics like money movement, ledgering and
                                    more to keep your team focused on differentiating your product and brand.
                                </p>
                                <p>Highnote makes the hardest parts of fintech simple.We cover the basics like money movement, ledgering and more to keep your
                                    team focused on differentiating your product and brand
                                </p>
                            </div>
                            <div>
                                <p className="learn-more1">Learn more
                                    <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                </p>
                            </div>

                        </div>
                        <div className="col-6 banner-team">
                            <div>
                                <h3 className='banner-head'>Team</h3>
                                <div className="about-us">
                                    <p >We are a tight of smart and focussed people who, over time, have built up a camaraderie and learnt to
                                        support and rely on each other.The work we do is equal parts intense,fascinating and rewarding.
                                    </p>
                                </div>
                                <div>
                                    <p>We are unbiased towards the use of different technology tools to solve different problems and
                                        realized there is no 'One size fits' all during our journey building finstop.com
                                    </p>
                                </div>
                                <div>
                                    <p className="learn-more2">Learn more
                                        <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default BannerFour;