import savinwealth from './savinwealth.svg';
import BannerImage from './BannerImage.png';
import Linkedin from './Linkedin.svg';
import Twitter from './Twitter.svg';
import Icon1 from './Icon1.svg';
import Icon3 from './Icon3.svg';
import Icon2 from './Icon2.svg';

export const Images = { 
    savinwealth,
    BannerImage,
    Linkedin,
    Twitter,
    Icon1,
    Icon2,
    Icon3

}