import React from 'react';
import { Images } from '../images/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';


class BannerThree extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="container banner3-background">
                    <h3 className="banner2-head">Savin Wealth Features</h3>
                    <div className="row banner-row">
                        <div className="col-sm-3 banner-box">
                            <img className="img-icon" src={Images.Icon1} alt="banner" />
                            <h4 className="banner3-head">Equity</h4>
                            <p className="banner3-para">Did you know there are more than 6000+ schemas that may fit your investment goals?
                                SW analysis and tools help you simplify the selection process.
                            </p>
                            <div className="learn-more-icon">
                                <h6 className="learn-more">Learn more
                                    <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                </h6>
                            </div>
                        </div>
                        <div className="col-sm-3 banner-box">
                            <img className="img-icon" src={Images.Icon3} alt="banner" />
                            <h4 className="banner3-head">Mutual funds</h4>
                            <p className="banner3-para">Need professional help on your investments? Use SW advisor database to find
                                the nearest mutual fund distributor/advisor/ in your area.
                            </p>
                            <div className="learn-more-icon">
                                <h6 className="learn-more">Learn more
                                    <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                </h6>
                            </div>
                        </div>
                        <div className="col-sm-3 banner-box">
                            <img className="img-icon" src={Images.Icon2} alt="banner" />
                            <h4 className="banner3-head">Insurance</h4>
                            <p className="banner3-para">Tried of filling repetitive and tedious forms during investments? SW has simplified
                                the registration process with least paperwork.
                            </p>
                            <div className="learn-more-icon1">
                                <h6 className="learn-more">Learn more
                                    <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row banner-row">
                        <div class="col-sm-2"></div>
                        <div className="col-sm-3 banner-box">
                            <img className="img-icon" src={Images.Icon2} alt="banner" />
                            <h4 className="banner3-head">Fixed Income</h4>
                            <p className="banner3-para">Tried of filling repective and tedious forms during investments? SW has
                                simplified the registration process with least paperwork.
                            </p>
                            <div>
                                <h6 className="learn-more">Learn more
                                    <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                </h6>
                            </div>
                        </div>
                        <div className="col-sm-3 banner-box">
                            <img className="img-icon" src={Images.Icon2} alt="banner" />
                            <h4 className="banner3-head">PMS</h4>
                            <p className="banner3-para">Tired of filling repectitive and  tedious forms during investments? SW has simplified
                                the registration process with least paperwork.
                            </p>
                            <div>
                                <h6 className="learn-more">Learn more
                                    <FontAwesomeIcon className='icon-angle' icon={faAngleRight} />
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BannerThree;