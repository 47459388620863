import React from 'react';
import { Images } from '../images/index';


class BannerOne extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div className="container">
                <div className="row">

                    <div className="col-6 banner-one">
                        <div className="">
                            <h1 className="banner-head">Build your wealth <br />
                            Invest in the best Mutual Funds</h1>
                        </div>
                        <div>
                            <p className="banner1-para">SW analysis and tools put you in the right
                            direction to choose the best mutual funds.</p>
                        </div>
                        <button type="button" className="btn btn-primary btn-signup">Sign Up Now</button>
                    </div>
                    <div className="col-6">
                        <img className="img-right" src={Images.BannerImage}  alt="demo" />
                    </div>

                </div>
            </div>

        )
    }
}

export default BannerOne;